<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" md="6" class="text-center">
        <div class="text-h1">404</div>
        <div v-html="$store.state.message404 || 'Not found'"></div>
        <v-btn color="primary ma-3" to="/">Go to Home page</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
};
</script>
